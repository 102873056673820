import { HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PreloadAllModules, RouterModule } from '@angular/router';
import { EnvServiceProvider, SgsPermissionService } from 'sgs-angular-components';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { HomeComponent } from './routes/home/home.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SharedModule } from './shared/shared.module';

import { SGWTConnectCore, SGWTConnectModule } from '@sgwt/connect-angular';
import { sgwtConnect } from '../sgwt-connect';
import { I18nService } from './shared/services/i18n.service';
import { APIS, BASE_PATH } from 'src/apis/backend';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
declare let __webpack_public_path__: string;

// AoT requires an exported function for factories
const httpLoaderFactory = (http: HttpClient): TranslateLoader =>
  new TranslateHttpLoader(http, __webpack_public_path__.replace(/\/?$/, '/assets/i18n/'));

// Export sgwtConnect factory (AOT requirement):
const sgwtConnectFactory = (): SGWTConnectCore => sgwtConnect;

// Export sgwtConnect predicate (AOT requirement):
// Only add the Authorization header with your private access_token to requests where url starts with "https://myapi"
const escapeRegExp = (str: string): string => str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
const sgwtConnectPredicate = ({ url }: HttpRequest<any>): boolean => {
  const env = (window as any).SGSCustom.sgsEnvProperties;
  const protocol = escapeRegExp(env.backend_api_protocol);
  const host = escapeRegExp(env.backend_api_host);
  const port = escapeRegExp(env.backend_api_port);
  const basePath = escapeRegExp(env.backend_api_basePath);
  const regExp = new RegExp(`^${protocol}?://${host}(?::${port})?/${basePath}`);
  return regExp.test(url) || url === env.sgConnect_user_info;
};

const API_BASE_PATH = `${(window as any).SGSCustom.sgsEnvProperties.backend_api_protocol}://${(window as any).SGSCustom.sgsEnvProperties.backend_api_host}`;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
    SGWTConnectModule.forRoot(sgwtConnectFactory, sgwtConnectPredicate),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
  ],
  providers: [
    I18nService,
    EnvServiceProvider,
    SgsPermissionService,
    { provide: BASE_PATH, useValue: API_BASE_PATH },
    ...APIS],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
