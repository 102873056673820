import {Component, OnDestroy, OnInit} from '@angular/core';
import { Observable, Subject, catchError, map, of, takeUntil, tap } from 'rxjs';
import { ProviderApiModel, ProviderService } from 'src/apis/backend';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  providerInfo?: ProviderApiModel | null;

  constructor(
    private readonly providerService: ProviderService) {}

  ngOnInit(): void {
    const unauthorizedUrl = `${(window as any).SGSCustom.sgsEnvProperties.unauthorizedSgMarketsUrl}`;
    this.providerService.apiV1ProvidersProviderNameGet('clarity').pipe(
      tap(result => this.providerInfo = result),
      catchError(() => {
        window.location.href = unauthorizedUrl;
        return of(null);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
