import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HttpFeedbackComponent } from './components/http-feedback/http-feedback.component';
import { httpFeedbackInterceptorProvider } from './interceptors/http-feedback.interceptor';
import { SgCurrencyPipe } from './pipes/sg-currency.pipe';
import { SgDateTimePipe } from './pipes/sg-date-time.pipe';
import { SgDatePipe } from './pipes/sg-date.pipe';
import { SgNumberPipe } from './pipes/sg-number.pipe';
import { HttpFeedbackService } from './services/http-feedback.service';
import { I18nService } from './services/i18n.service';
import { VendorServiceComponent } from './components/vendor-service/vendor-service.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [HttpFeedbackComponent, SgCurrencyPipe, SgDatePipe, SgDateTimePipe, SgNumberPipe, VendorServiceComponent, SafePipe],
  imports: [BrowserModule, TranslateModule],
  exports: [HttpFeedbackComponent, SgCurrencyPipe, SgDatePipe, SgDateTimePipe, SgNumberPipe, VendorServiceComponent, SafePipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [httpFeedbackInterceptorProvider, HttpFeedbackService, I18nService]
    };
  }
}
// https://alligator.io/angular/providers-shared-modules/
