<nav class="navbar navbar-expand-lg navbar-light bg-white">

  <div class="d-flex align-items-center justify-content-between w-100">
    <a routerLink="/"  class="navbar-brand">
      <img alt="Logo Société Générale" src="./assets/logo.svg" height="32" width="32">
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active" translate>menu.home</a>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <sgwt-account-center
        #sgwtAccountCenter
        [availableLanguages]="translate.allLangsForAccountCenter"
        language="EN"
        authentication="sg-connect-v2"
        [environment]="env.environment === 'production' ? '' : env.environment"
        mode="sg-markets"
        show-languages-selection="true"
        show-sign-in-button="true"
      >
      </sgwt-account-center>

      <svg class="d-none d-md-block overflow-visible ml-3" width="135" height="32">
        <image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/sg_market.svg" x="0" y="0" height="16" width="132"></image>
        <!--Change here name of services-->
        <text class="font-family-display" x="0" y="32" height="16">An SGSS Webapp</text>
      </svg>
    </div>

  </div>
</nav>
