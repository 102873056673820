import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { SgsEnvPropertiesService } from 'sgs-angular-components';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sgwtAccountCenter', { static: false })
  private sgwtAccountCenter?: ElementRef;
  private isListeningToReadyEvent = false;

  constructor(public translate: I18nService, public env: SgsEnvPropertiesService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngAfterViewInit(): void {
    if (this.sgwtAccountCenter) {
      // Add an event listener to catch the language modification event from the Account Center.
      this.sgwtAccountCenter.nativeElement.addEventListener(
        'sgwt-account-center--language-changed',
        this.onLanguageChanged
      );
      setTimeout(() => this.updateWidgetWhenReady(), 0);
    }
  }

  private updateWidgetWhenReady(): void {
    this.isListeningToReadyEvent = (this.sgwtAccountCenter?.nativeElement.changeLanguage === undefined);
    if (this.isListeningToReadyEvent) { // not yet initialized: wait for the ready event…
      this.sgwtAccountCenter?.nativeElement.addEventListener('sgwt-account-center--ready', this.onReady);
    } else { // already initialized: apply the change immediately
      this.onReady();
    }
  }

  ngOnDestroy(): void {
    if (this.sgwtAccountCenter) {
      this.sgwtAccountCenter.nativeElement.removeEventListener(
        'sgwt-account-center--language-changed',
        this.onLanguageChanged
      );
      if (this.isListeningToReadyEvent) {
        this.sgwtAccountCenter.nativeElement.removeEventListener('sgwt-account-center--ready', this.onReady);
      }
    }
  }

  onReady = () => {
    this.translate.useNegociatedBrowserLang().subscribe(l => this.sgwtAccountCenter?.nativeElement.changeLanguage(l.toUpperCase()));
  };

  onLanguageChanged = (evt: CustomEvent) => {
    this.translate.use(evt.detail.language.toLowerCase());
  };
}
