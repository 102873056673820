import { Component } from '@angular/core';
import { delay, map, Observable } from 'rxjs';
import { HttpFeedbackService } from './shared/services/http-feedback.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showSpinner: Observable<boolean>;

  constructor(httpFeedback: HttpFeedbackService) {
    this.showSpinner = httpFeedback.activeRequestsCount.pipe(
      delay(0), // https://blog.angular-university.io/angular-debugging/
      map(count => count > 0)
    );
  }
}
